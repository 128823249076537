import React, { useContext, useMemo } from "react";
import Logo from "../Logo";
import styles from "./style.module.scss";
import userPic from "./user-pic.png";
import { NavLink, Route, Switch } from "react-router-dom";
import UserSearch from "../Search/usersSearch";
import NotificationsSearch from "../Search/notificationsSearch";
import { RootContext } from "@services/react";
import { observer } from "mobx-react";
import Dropdown, { DropdownItem } from "@components/Common/Dropdown";
import { getConfig } from "@di";
import { URLS } from "@services/router";
import { FormattedMessage } from "react-intl";
import { IntlContext } from "@components/App/IntlProvider";

//TODO use react-select or remove react-select
const Header = () => {
  const { user, logout } = useContext(RootContext).authStore;
  const { enableDutyPhones, enablePts } = getConfig();
  const {
    locales,
    switchLocale,
    locale: currentLocale,
  } = useContext(IntlContext);

  const dropdownItems = useMemo<DropdownItem[]>(
    () => [
      {
        title: (
          <>
            <FormattedMessage
              defaultMessage="Logout"
              description="User dropdown logout"
            />
            &nbsp;&nbsp;
            <i className="far fa-sign-out"></i>
          </>
        ),
        className: "logout-btn",
        onSelect: logout,
      },
    ],
    [],
  );

  if (locales.length > 1) {
    dropdownItems.unshift(
      ...locales.map((locale) => ({
        title:
          locale === currentLocale ? (
            <b>{locale.toUpperCase()}</b>
          ) : (
            locale.toUpperCase()
          ),
        onSelect: () => switchLocale(locale),
      })),
    );
  }

  const title = useMemo(
    () => (
      <>
        {user && (user.profile.full_name || user.profile.email)}
        <i className="far fa-angle-down" />
        <img src={userPic} />
      </>
    ),
    [],
  );

  return (
    <>
      <header className={styles.header}>
        <Logo />
        <Switch>
          <Route path={URLS.USERS} exact>
            <UserSearch />
          </Route>
          <Route path={URLS.NOTIFICATIONS}>
            <NotificationsSearch />
          </Route>
        </Switch>
        <Dropdown
          className={styles.userDropdown}
          title={title}
          items={dropdownItems}
        />
      </header>
      <nav className={styles.nav}>
        {user?.profile.is_user_admin && (
          <NavLink to={URLS.USERS} exact>
            <FormattedMessage
              defaultMessage="Users"
              description="Users tab title"
            />
          </NavLink>
        )}
        {user?.profile.is_notification_admin && (
          <NavLink to={URLS.NOTIFICATIONS}>
            <FormattedMessage
              defaultMessage="Notifications"
              description="Notifications tab title"
            />
          </NavLink>
        )}
        {enableDutyPhones && user?.profile.is_user_admin && (
          <NavLink to={URLS.DUTY_PHONES}>
            <FormattedMessage
              defaultMessage="Duty phones"
              description="Duty phones tab title"
            />
          </NavLink>
        )}
        {enablePts && user?.profile.is_pts_admin && (
          <NavLink to={URLS.PTS}>
            <FormattedMessage
              defaultMessage="PTS"
              description="PTS tab title"
            />
          </NavLink>
        )}
      </nav>
    </>
  );
};
export default observer(Header);
