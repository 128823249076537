export const triggerKeys = ["happened", "not_happened"] as const;

export const turnLengthsKeys = ["SHORT", "LONG"] as const;

export const severityKeys = ["HIGH", "MEDIUM", "LOW"] as const;

export const TOW_ON = "TOW_ON";
export const TOW_OFF = "TOW_OFF";
export const INBOUNDS_ONLY = "INBOUNDS_ONLY";
export const FULL_FLIGHTS_ONLY = "FULL_FLIGHTS_ONLY";

export const SET = "SET";
export const EMPTY = "EMPTY";

export const SEVERITY_LOW = "LOW";
export const SEVERITY_MEDIUM = "MEDIUM";
export const SEVERITY_HIGH = "HIGH";

export const SHORT = "SHORT";
export const LONG = "LONG";

export type Trigger = (typeof triggerKeys)[number];

const UNIFIED = "unified";
const SAFETY_EVENT = "safety-event";
const SAFETY_ALERT = "safety-alert";
const PUSHBACK_SPEED_EVENT = "pushback-speed-safety-event";
const CATERING_HANDRAILS_EVENT = "catering-handrail-safety-event";
const BELT_GUARDRAIL_EVENT = "belt-guardrail-safety-event";

type WithoutId<T> = Omit<T, "id">;

export type Severity =
  | typeof SEVERITY_HIGH
  | typeof SEVERITY_MEDIUM
  | typeof SEVERITY_LOW;

export type TriggerKeys = (typeof triggerKeys)[number];

export type TurnaroundEventAttribute = {
  type: "event";
  objectName: string;
  objectType: string | null;
  eventName: string;
  objectPosition: string | null;
};

export type TurnaroundParameterAttribute = {
  type: "turnaround_param";
  parameterName: string;
};

export type MergedTurnaroundAttributeType =
  | TurnaroundEventAttribute["type"]
  | TurnaroundParameterAttribute["type"];

export type MergedTurnaroundAttribute = {
  type: MergedTurnaroundAttributeType;
} & Omit<TurnaroundEventAttribute, "type"> &
  Omit<TurnaroundParameterAttribute, "type"> &
  Pick<TurnaroundPointInTime, "timeShift">;

export type TurnaroundAttribute =
  | TurnaroundEventAttribute
  | TurnaroundParameterAttribute;

export type TurnaroundPointInTime = {
  timeShift: number;
  turnaroundAttribute: TurnaroundAttribute;
};

type SearchInterval = {
  startEvent: TurnaroundAttribute;
  startTimeShift?: number;
  endEvent: TurnaroundAttribute;
  endTimeShift?: number;
};

type UnifiedIncidentConfigData = {
  incidentType: typeof UNIFIED;
  type: typeof UNIFIED;
  detectedAttributes: TurnaroundAttribute[];
  endRangeBoundary: TurnaroundPointInTime | null;
  firingPointInTime: TurnaroundPointInTime | null;
  startRangeBoundary: TurnaroundPointInTime;
  trigger: Trigger;
};

type SafetyEventConfigData = {
  intervalToSearchIn?: SearchInterval;
  inverseOperation?: boolean;
  minRangeDuration?: number;
  opNameToSearchFor?: string;
  trigger: Trigger;
  type: typeof SAFETY_EVENT;
  incidentType: typeof SAFETY_EVENT;
};

type SafetyAlertIncidentData = {
  safetyEventConfigId?: string;
  type: typeof SAFETY_ALERT;
  incidentType: typeof SAFETY_ALERT;
};

type CateringHandrailConfigData = {
  greenOperations?: string[];
  redOperations?: string[];
  type: typeof CATERING_HANDRAILS_EVENT;
  incidentType: typeof SAFETY_EVENT;
};

type PushbackSpeedConfigData = {
  pushbackSpeedLimit: number;
  type: typeof PUSHBACK_SPEED_EVENT;
  incidentType: typeof SAFETY_EVENT;
};

type GuardrailConfigData = {
  type: typeof BELT_GUARDRAIL_EVENT;
  incidentType: typeof SAFETY_EVENT;
};

type BaseIncidentConfig = {
  id: string;
  active: boolean;
  customText: string | null;
  excludedAircraftTypes: string[];
  excludedStands: string[];
  group: string;
  inboundFlightStatus: typeof SET | typeof EMPTY | null;
  outboundFlightStatus: typeof SET | typeof EMPTY | null;
  requiredAircraftTypes: string[];
  requiredStands: string[];
  severity: Severity;
  requiredTurnaroundLength: typeof SHORT | typeof LONG | null;
  incidentType: typeof UNIFIED | typeof SAFETY_EVENT | typeof SAFETY_ALERT;

  requiredAirlineIcaoAndIata: string[];
  excludedAirlineIcaoAndIata: string[];
};

export type UnifiedIncidentConfig = BaseIncidentConfig & {
  data: UnifiedIncidentConfigData;
};

export type SafetyAlertIncidentConfig = BaseIncidentConfig & {
  data: SafetyAlertIncidentData;
};

export type SafetyEventIncidentConfig = BaseIncidentConfig & {
  data:
    | SafetyEventConfigData
    | PushbackSpeedConfigData
    | CateringHandrailConfigData
    | GuardrailConfigData;
};

export type NewUnifiedIncidentConfig = WithoutId<UnifiedIncidentConfig>;

export type NewSafetyEventIncidentConfig = WithoutId<SafetyEventIncidentConfig>;

export type NewSafetyAlertIncidentConfig = WithoutId<SafetyAlertIncidentConfig>;

export type IncidentConfig =
  | UnifiedIncidentConfig
  | SafetyEventIncidentConfig
  | SafetyAlertIncidentConfig;

export type NewIncidentConfig =
  | NewUnifiedIncidentConfig
  | NewSafetyEventIncidentConfig
  | NewSafetyAlertIncidentConfig;
